//.c-box関連のjs
boxSlider();

function boxSlider() {
  const target = '.c-boxSlider__container';
  const flag = classFinder(target);

  if (flag) {
    const slider = new Swiper(target, {
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1000,
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 30,

      // ナビボタン
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        900: {
          spaceBetween: 70,
        },
      }
    });
  }
}